//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import serviceComponent from "./serviceComponent";
import { servicesArr } from "../constants/services.const";
import sectionTitleComponent from "../components/sectionTitleComponent";
import packageComponent from "../components/packageComponent";
import packageItem from './packageItem';
import copyComponent from './copyComponent';
import iconComponent from './iconComponent';

export default {
    name: "ServicePage",
    data() {
        return {
            service: null,
            isEven: false,
        };
    },

    components: {
        serviceComponent,
        sectionTitleComponent,
        packageComponent,
        packageItem,
        copyComponent,
        iconComponent
    },

    beforeRouteUpdate(to, from, next) {
        this.loadData(to.params.serviceId);
        this.isEven = servicesArr.findIndex(item => item.id === to.params.serviceId) % 2 === 0;
        next();
    },

    methods: {
        loadData(serviceId) {
            this.service = servicesArr.find(
                (aService) => aService.id === serviceId
            );
        },
    },

    mounted() {
        this.loadData(this.$route.params.serviceId);
        this.isEven = servicesArr.findIndex(item => item.id === this.$route.params.serviceId) % 2 === 0;
    },
};
